/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import "popper.js/dist/popper.min.js"
import "bootstrap/dist/js/bootstrap.min.js"
import "jquery/dist/jquery.slim.min.js"
import "./static/styles/css/style.css"
import "./static/font/css/all.min.css"
import "lazysizes"

// scroll to top on browser back click
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  window.scrollTo(0, 0)
  return false
}
